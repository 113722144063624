body {
    .nav {
        border-color: #fff;
        box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    }

    .slider-box {
        padding: -15px;
        color: black;

        & .img-slider {
            color:white;
        }
    }
    
    .section-title {
        &.left {
            text-align: left;
        }

        &.center {
            text-align: center;
        }

        padding-bottom: 30px;

        &.center > h2 {
            font-size: 32px;
            font-weight: 600;
            margin-bottom: 20px;
            padding-bottom: 20px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 40px;
                height: 3px;
                background: #21a39a;
                bottom: 0;
                left: calc(50% - 20px);
            }
        }
    }

    .outstanding-box {
        & > .card {
            border: none;
            padding: 15px;

            & .icon {
                margin: 0 auto;
                width: 64px;
                height: 64px;
                background: #eaf4fb;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                transition: ease-in-out 0.3s;
                color: #21a39a;
            }
            
            &:hover {
                border-color: #fff;
                box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
            }

            & .icon {
                text-align: center;
                margin-bottom: 1em;
            }

            & h4 {
                color: #384046;
                transition: ease-in-out 0.3s;
                text-align: center;

                &:hover {
                    color: #21a39a;
                    cursor: pointer;
                    // text-decoration: underline;
                }
            }

            & p {
                margin: 15px 15px;
                text-align: justify;
            }
        }
    }

    .img-logo {
        width: 6em;
        height: auto;
    }

    .primary-color {
        color: #21a39a;
    }

    .secondary-color {
        color: #3074b6;
    }
}

.fixed-top {
    position: fixed;
    top: 40px !important;
    right: 0;
    left: 0;
    z-index: 1030;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.position-init {
    top:0 !important;
    transition-duration: 0.1s;
}

.scrolltable-y {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
        background-color: #000000;
    }
}

@import 'section.scss';