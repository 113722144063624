.content-box {
    min-height: calc(100vh - 20.8em);
}

.rrss-box {
    & .dropdown-item.active, & .dropdown-item:active, & .dropdown-item:hover {
        background-color: #21a39a !important;
        color: white !important;
    }

    & a {
        margin: 0px;
        cursor: pointer;
    }
}

.news-outstanding {
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    background: #fff;

    h4 {
        font-weight: 700;
        margin-bottom: 2px;
        font-size: 18px;
    }

    span {
        font-style: italic;
        display: block;
        font-size: 13px;
    }
    
    .more {
        margin-top: 15px;
    }
}

footer {
    .information {
        p {
            font-size: 14px !important;
            line-height: 24px;
            margin-bottom: 0;
            font-family: "Raleway", sans-serif !important;
            color: white;

            strong {
                color: white;
                font-weight: strong;
            }
        }
    }

    #quick-links {
        color: white;

        a {
            text-decoration: none;
            color: white;
            cursor: pointer;
            
            .fas {
                fill: #21a39a !important;
            }
        }
    }

    .footer-img {
        width: 80%;
    }

    #copyright-rrss {
        background-color: #333;
        height: 50px;
    }
}

#gallery {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;

    .filter-active {
        background: #21a39a;
        color: #fff;
    }

    li {
        cursor: pointer;
        margin: 0 15px 15px 0;
        display: inline-block;
        padding: 10px 20px;
        font-size: 12px;
        line-height: 20px;
        color: #444444;
        border-radius: 4px;
        text-transform: uppercase;
        background: #fff;
        margin-bottom: 5px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: #21a39a;
            color: #fff;
        }
    }
}

.gallery-box {

    &>.gallery-item {
        height: 360px;
        position: relative;
        overflow: hidden;

        &>.gallery-item-box {
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
            transition: 0.3s;

            figure {
                background: #000;
                overflow: hidden;
                height: 240px;
                position: relative;
                border-radius: 4px 4px 0 0;
                margin: 0;
            }

            .link-preview,
            .link-details {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                line-height: 0;
                text-align: center;
                width: 36px;
                height: 36px;
                background: #fff;
                border-radius: 50%;
                transition: all .2s linear;
                overflow: hidden;
                font-size: 20px;
            }

            .link-preview {
                left: calc(50% - 38px);
                top: calc(50% - 18px);

                &>svg {
                    fill: #384046;
                    line-height: 0;
                }
            }

            .link-details {
                right: calc(50% - 38px);
                top: calc(50% - 18px);

                &>svg {
                    fill: #384046;
                    line-height: 0;
                }
            }

            .info {
                background: #fff;
                text-align: center;
                padding: 30px;
                height: 90px;
                border-radius: 0 0 3px 3px;

                h4 {
                    font-size: 18px;
                    line-height: 1px;
                    font-weight: 700;
                    margin-bottom: 18px;
                    padding-bottom: 0;
                }

                p {
                    padding: 0;
                    margin: 0;
                    color: #b8b8b8;
                    font-weight: 500;
                    font-size: 14px;
                    text-transform: uppercase;
                }
            }

            &:hover {
                box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
            }
        }
    }


    &>.fadeInUp {
        -webkit-animation-name: fadeInUp;
        animation-name: fadeInUp;
    }
}

.social-media {
    background-color: #21a39a;
    opacity: 0.8;
    border-radius: 0 15px 15px 0;
    float: right; 
    left: 0;
    position:fixed; 
    top: 12em; 
    z-index: 1000;
}